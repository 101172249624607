
















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, User, Global } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import PropUtils from '@/modules/PropUtils';
import TouHistoryTab from '@/pages/touManagement/TouHistoryTab.vue';
import ConfigTouTab from '@/pages/touManagement/ConfigTouTab.vue';
import TrendsTab from '@/pages/touManagement/TrendsTab.vue';
import { TariffDataProp, TariffData } from '@/modules/Types';
import { setProjectProperty } from '@/modules/ApiUsers';
import { Watch } from 'vue-property-decorator';
import { isEqual } from 'lodash';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

@Component({
    components: {
        TouHistoryTab,
        ConfigTouTab,
        TrendsTab,
        ConfirmDialog
    }
})

export default class TouConfig extends Vue {
    @Props.State('list') projectProperties;
    @Props.Mutation('edit') updatePropertyLocally;
    @User.State('project') project;
    @User.State('role') role;
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Global.Action('throwNotify') throwNotify;

    loading = true;
    tou_tariff_prop = null;
    selected_tab = 'edit';
    selected_tou = null;
    open_confirm_delete_dialog = false;
    item_to_delete = null;
    
    mounted() {
        this.setPageTitle('TOU Management');
        this.setTariffProp();
        this.loading = false;
    }

    get isAdmin(){
        return this.role === 'CA' || this.role === 'CM';
    }

    setTariffProp(){
        const tou_tariff = PropUtils.getProperty(this.projectProperties, 'device.time_of_use_tariff');
        if (tou_tariff) {
            const prop_value: TariffDataProp = cloneDeep(tou_tariff).value;
            Object.values(prop_value).forEach((year_tariff_data: TariffData[]) => 
                year_tariff_data.forEach((tariff: TariffData) => tariff.months.sort((a, b) => a - b))
            );
            this.tou_tariff_prop = prop_value;
        }else {
            this.tou_tariff_prop = {};
        }
    }

    setSelectedTab(tab) {
        this.selected_tab = tab;
    }

    editSelectedTOU(selected) {
        if (!selected) {
            this.selected_tou = null;
            return;
        }
        const index = this.tou_tariff_prop[selected.year].findIndex((tou) => isEqual(tou.months, selected.months));
        this.selected_tou = cloneDeep({...selected, index});
    }

    openConfirmDialog(item) {
        this.open_confirm_delete_dialog = true;
        this.item_to_delete = item;
    }

    deleteTOU() {
        const prop_data = cloneDeep(this.tou_tariff_prop);
        const selected_year = this.item_to_delete.year;
        const year_tou_data = prop_data[selected_year];
        if (year_tou_data.length === 1) {
            delete prop_data[selected_year];
        }else {
            prop_data[selected_year] = prop_data[selected_year].filter((tou) => !isEqual(tou.months, this.item_to_delete.months));
        }

        this.updateTouProp(prop_data);
        this.open_confirm_delete_dialog = false;
    }

    updateTouProp(prop_data){
        setProjectProperty(
            this.project.company,
            this.project.id,
            'device.time_of_use_tariff',
            prop_data
        ).then((res) => {
            if (res.hasOwnProperty('response')) {
                this.selected_tou = null;
                this.throwNotify({
                    type: 'success',
                    title: this.$t('Success'),
                    text: this.$t(`TOU data was saved successfully`)
                });
                this.tou_tariff_prop = res.response.value;
                this.setSelectedTab('history');
                this.updatePropertyLocally(res.response);
            }}
        ).catch(() => {
            this.throwNotify({
              type: 'error',
              title: this.$t('Error'),
              text: this.$t(`Couldn't update TOU data`)
            });
        });
    }
}
